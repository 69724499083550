<template>
  <div class="product">
    <div class="zhts">
      <div class="zhts_box">
        <p>智慧图书</p>
        <p>24小时智能化无人值守的微型图书室</p>
        <p>
          图书馆智能管理，落实并推进全民悦读，营造阅读氛围，提升办公人员素质
        </p>
        <el-button>在线咨询</el-button>
      </div>
    </div>
    <div class="yycj">
      <p>应用场景</p>
      <div class="yycj_box">
        <img class="img" src="@/assets/images/product/zhts/yycj.png" />
      </div>
    </div>
    <div class="hxld">
      <p>核心亮点</p>
      <p>我要读书模块包含扫码开门、扫码借书、借阅记录、图书查询四个功能。</p>
      <div class="hxld_box">
        <img class="img" id="img" src="@/assets/images/product/zhts/hxld.png" />
        <div class="hxld_box_content" id="title">
          <p>扫码开门</p>
          <p>
            点击扫码开门功能，页面生成开门二维码，提示“请将二维码对准门锁设备摄像头”，核对完成后，门自动开锁
          </p>
        </div>
      </div>
    </div>
    <div class="smjs">
      <div class="smjs_box">
        <div class="smjs_box_content" id="title2">
          <p>扫码借书</p>
          <p>
            点击扫码借书功能，提示“请将图书二维码放入框内，将自动扫描”，扫码图书上面的二维码提交借阅申请，实时申请通过返回借阅成功结果回复
          </p>
        </div>
        <img
          class="img"
          id="img2"
          src="@/assets/images/product/zhts/smjs.png"
        />
      </div>
    </div>
    <div class="jyjl">
      <div class="jyjl_box">
        <img
          class="img"
          id="img3"
          src="@/assets/images/product/zhts/jyjl.png"
        />
        <div class="jyjl_box_content" id="title3">
          <p>借阅记录</p>
          <p>点击借阅记录功能，可查看本人的图书借阅记录</p>
        </div>
      </div>
    </div>
    <div class="tscx">
      <div class="tscx_box">
        <div class="tscx_box_content" id="title4">
          <p>图书查询</p>
          <p>关键字查询图书，图书借阅状态浏览查看</p>
        </div>
        <img
          class="img"
          id="img4"
          src="@/assets/images/product/zhts/tscx.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data () {
    return {

    }
  },
  mounted () {
    $(window).scroll(this.scrollBar)
  },
  methods: {
    scrollBar () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      var hxldH = $('.hxld').offset().top
      var smjsH = $('.smjs').offset().top
      var jyjlH = $('.jyjl').offset().top
      var tscxH = $('.tscx').offset().top
      if (h > hxldH - 500) {
        $('#img').addClass('animation')
      }
      if (h > smjsH - 500) {
        $('#img2').addClass('animation')
      }
      if (h > jyjlH - 500) {
        $('#img3').addClass('animation')
      }
      if (h > tscxH - 500) {
        $('#img4').addClass('animation')
      }
    }
  },
  destroyed () {
    $(window).unbind('scroll', this.scrollBar)
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
